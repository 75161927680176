.WrapNewShipmentPage {
  margin: 30px 40px;
}
.NewShipmentTitle {
  font-size: 24px;
  line-height: 38px;
  color: #2c4a6b;
  /* font-family: "Nunito", sans-serif; */
  font-weight: bold;
}
.btn-newShipment {
  border-radius: 8px;
  background: #26aa5d;
  padding: 15px 30px;
  color: #fff;
  border: none;
}

/* form------------------------- */
.NewShipment-TitleTb {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  color: #2c4a6b;
  /* font-family: "Nunito", sans-serif; */
  font-weight: 700;
}
.NewShipment-ErrorM {
  margin-top: 8px;
  color: darkred;
}
.NewShipment-label {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px !important;
  margin: auto 0;
}
.NewShipment-checkbox{
  border: 2px solid #b6b7b8;
  border-radius: 0;
  height: 25px;
  width: 25px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.NewShipment-checkbox-label{
  font-weight: 600;
  font-size: 16px;
  margin: auto 0;
}

.NewShipment-inputS {
  border-left: 3px solid darkred !important;
  border: 1px solid #ced4da;
  padding: 4px;
}
.NewShipment-input {
  border: 1px solid #ced4da;
  padding: 4px;
}
.form-block {
  padding: 5px;
  /* margin-bottom: 25px; */
}
.form-block-m {
  border: 1px solid #2c4a6b;
  padding: 15px;
}
.wrap-input {
  margin-bottom: 15px;
}
.NewShipment-fix{
  display: grid;
}

.NewShipment-wrapBtnSubmit{
  margin-top: 30px;
}

/* mobile====================================================================================================== */
.NewShipment-background{
  background: #f7f8fa;
}
.NewShipmentTitleMb{
  font-size: 44px;
  line-height: 38px;
  color: #2c4a6b;
  /* font-family: "Nunito", sans-serif; */
  font-weight: bold;
  margin-bottom: 10px;
}
.NewShipment-TitleTb-Mb{
  font-size: 38px;
  line-height: 22px;
  margin-bottom: 25px;
  color: #2c4a6b;
  /* font-family: "Nunito", sans-serif; */
  font-weight: 700;
  margin-top: 10px;
}

.form-block-Mb{
  margin-top: 30px;
  padding: 5px;
}

.NewShipment-label-mb{
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 8px !important;
  margin: auto 0;
}

.NewShipment-checkbox-label-mb{
  font-weight: 600;
  font-size: 36px;
  margin: auto 0;
}
.NewShipment-checkbox-mb{
  border: 2px solid #b6b7b8;
  border-radius: 0;
  height: 80px;
  width: 80px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}
.NewShipment-inputS-mb {
  border-left: 3px solid darkred !important;
  border: 1px solid #ced4da;
  padding: 25px;
}
.NewShipment-input-mb {
  border: 1px solid #ced4da;
  padding: 25px;
}
.form-block-mMb {
  border: 1px solid #2c4a6b;
  padding: 25px 35px 25px 35px;
  font-size: 36px;
}
.NewShipment-DateFix{
  font-size: 36px;
  border-left: 3px solid darkred !important;
  border: 1px solid #ced4da;
  padding: 20px;
}
.btn-newShipment-mb {
  border-radius: 8px;
  background: #26aa5d;
  padding: 27px 27px;
  color: #fff;
  border: none;
  font-size: 36px;
}