/*L-desktop*/
@media (min-device-width: 1600px) {
    .DetailNewsTitle {
        margin: 20px 0 10px 0;
        text-align: center;
        font-weight: bold;
        line-height: 1.6;
        color: rgb(137, 44, 11);
        font-size: 30px;
    }
    .DetailNewsContainer {
        position: relative;
        min-height: 100vh;
        padding-bottom: 450px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1440px) {
    .DetailNewsTitle {
        margin: 20px 0 30px 0;
        text-align: center;
        font-weight: bold;
        line-height: 1.6;
        color: rgb(137, 44, 11);
        font-size: 60px;
    }
    .DetailNewsContent{
        margin: 0 30px;
        font-size: 30px;
    }
}

@media only screen and (max-device-width: 319px) {
    .DetailNewsTitle {
        margin: 20px 0 30px 0;
        text-align: center;
        font-weight: bold;
        line-height: 1.6;
        color: rgb(137, 44, 11);
        font-size: 60px;
    }
    .DetailNewsContent{
        font-size: 30px;
        margin: 0 30px;
    }
}

.DetailNewsWrapFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.DetailNewsContainer>.DetailNewsWrap>div>div>ul{
    list-style: unset;
}
.DetailNewsContainer>div>ul{
    list-style: unset;
}
.DetailNewsContent>div>h1:first-child{
    display: none;
}