.footer-h {
  background-image: url("../../images/Banner/banner-inland.png");
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  background-position: bottom;
  /* background-attachment: fixed; */
  padding-bottom: 400px;
}
.footer-hMb {
  background-image: url("../../images/Banner/banner-inland.png");
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding-bottom: 1800px;
}

.FooterOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  z-index: 2;
}

.FooterTest {
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 50px 0 0 0;
}

.bg-img-fix {
  background-size: cover;
}

.text-white {
  color: #fff;
}

ul {
  list-style: none;
}

.dez-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
  text-align: center;
}

.ft-ic {
  display: flex;
}

.bot {
  margin-top: 2vh;
}

.hin {
  padding-left: 0px;
}

.widget_getintuch li {
  margin-bottom: 20px;
  position: relative;
}

.bot p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
  color: #c48a47;
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  color: rgb(255, 255, 255);
}

.abc {
  padding: 0 6px;
}

.footer-icon {
  width: 36px;
  border-radius: 25px;
}

.footer-line-1 {
  border-bottom: 2px solid white;
  max-width: 180px;
  margin-bottom: 20px;
}

.Footer-link-web{
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.Footer-link-web:hover{
  color: #07b404;
}
/* mobile========================================================================================= */
.Wrap-footer-mb {
  margin: 0 50px;
}

.Footer-introCt-mb {
  font-size: 36px;
}

.Footer-introText-mb {
  text-indent: 20px;
  text-align: justify;
}

.logo-footer-mb {
  width: 60%;
}

.bot-mb {
  margin-top: 1vh;
  font-size: 36px;
  color: #C48A47;
}

.Footer-media-mb {
  margin: 35px 0;
}

.Footer-media-mb ul {
  padding-left: 0;
}

.footer-icon-mb {
  width: 80px;
  border-radius: 50px;
}

.footer-title-mb {
  font-size: 38px;
  font-weight: bold;
  line-height: 1.4;
  color: rgb(255, 255, 255);
}