.ServiceSitesBanner {
    margin: 0 50px;
}

.ServiceSitesBannerImg {
    width: 100%;
}
.ServiceSitesTitle{
    margin: 30px 0;
    text-align: center;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
}

.ServiceSitesIcon{
    display: flex;
    margin-bottom: 30px;
}
.ServiceSitesIcon-Wrap{
    text-align: center;
    width: 20%;
}
.ServiceSitesIcon-Img{
    width: 70px;
}

/* mobile */
.ServiceSitesTitleMb{
    font-size: 42px;
    margin: 30px 0;
    text-align: center;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
}