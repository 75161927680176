.IntroduceBanner {
  margin: 0 50px;
}

.IntroduceBannerImg {
  width: 100%;
}

.IntroduceWrapContent {
  margin: 50px 70px 0 70px;
}

.IntroduceWrapTitle {
  margin-left: auto;
}

.IntroduceTitle {
  text-align: center;
  /* font-family: Roboto, sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
}

.IntroduceWrapContentB {
  display: flex;
}

.IntroduceWrapContentR {
  /* font-family: Roboto, sans-serif; */
  font-size: 18px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
}

.IntroduceWrapContentR2 {
  text-indent: 20px;
  text-align: justify;
}

.IntroduceCompanyProfile {
  margin-top: 15px;
}

.IntroduceCompanyProfileTitle {
  /* font-family: Roboto, sans-serif; */
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: left;
}

.IntroduceList {
  list-style-type: none;
  padding-left: 0;
}

.IntroduceList li::before {
  content: "♦ ";
}

.Introduce-btnI {
  padding: 0px 25px;
  border: 1px solid #000;
  background: #fff;
  border-radius: 5px;
  font-weight: 500;
}

/* mobile */
.IntroduceWrapContentMb {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.IntroduceTitleMb {
  text-align: center;
  /* font-family: Roboto, sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  font-size: 50px;
}

.IntroduceWrapContentB {
  display: flex;
}

.IntroduceWrapContentRMb {
  /* font-family: Roboto, sans-serif; */
  font-size: 38px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
}

.IntroduceWrapContentR2 {
  text-indent: 20px;
  text-align: justify;
}

.IntroduceCompanyProfile {
  margin-top: 15px;
}

.IntroduceCompanyProfileTitleMb {
  /* font-family: Roboto, sans-serif; */
  font-size: 44px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: left;
}

.IntroduceList {
  list-style-type: none;
  padding-left: 0;
}

.IntroduceList li::before {
  content: "♦ ";
}

.IntroduceWrapContentR>div>h1:first-child {
  display: none;
}
.IntroduceWrapContentRMb>div>h1:first-child {
  display: none;
}

.IntroduceWrapContentR>div>figure>table>tbody>tr>td {
  border-bottom: hidden;
}
.IntroduceWrapContentRMb>div>figure>table>tbody>tr>td {
  border-bottom: hidden;
}

.IntroduceWrapContentR>div>figure>table{
  width: 100%;
}
.IntroduceWrapContentRMb>div>figure>table{
  width: 100%;
}
.IntroduceWrapContentR>div>figure>table>tbody>tr{
  background: #f5f5f5;
}
.IntroduceWrapContentRMb>div>figure>table>tbody>tr{
  background: #f5f5f5;
}