.news-card {
    background-color: rgb(137, 44, 11);
    height: 380px;
}

.news-card__img {
    box-shadow: rgb(241, 243, 244) 0px 15px 20px -15px;
    height: 221px;
}

.news-card__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-card__btn {
    background-color: white !important;
    color: black !important;
}

@media (max-width: 1224px)  {
    .news-card {
        height: 600px;
    }

    .news-card__img  {
        height: 350px;
    }

    .news-card__title {
        font-size: 28px  !important;
    }

    .news-card__btn {
        font-size: 32px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}