.whySunny {
  background: #f4f7f8;
  margin-top: 30px;
  padding-bottom: 30px;
}

.sunny-box {
  margin-top: 20px !important;
}
.sunny-box-ss {
  padding-top: 20px !important;
}

.sunny-box .sunny-title {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}

.sunny-title-ss {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}

.sunny-box h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 34px;
  color: #892c0b;
  margin-bottom: 24px;
}

.whySunny-ss {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 34px;
  color: #892c0b;
  margin-bottom: 24px;
}

.sunny-box .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: justify;
  color: #000;
}

.sunny-thum .media {
  margin: 30px 0;
}

.sunny-thum .media .media-body {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  padding-left: 20px;
  color: #343a40;
  padding-top: 20px;
}

.media {
  display: flex;
  align-items: flex-start;
}

/*------------------------ right------------------------ */

.sheet-box-whySunny {
  background: #f8e6bc;
  padding: 40px;
}

.sheet-box-whySunny .title-custom {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #c75a16;
}

.title-custom {
  margin-bottom: 12px;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

*,
::after,
::before {
  box-sizing: border-box;
}

div {
  display: block;
}

body {
  background-color: #fff;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  font-size: 16px;
  font-weight: 400;
  color: #2c4a6b;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.input-group-text {
  border: unset !important;
}

.mh-5 {
  border: unset !important;
}

.sheet-box-whySunny button {
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  background: #892c0b;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
}

.inputWhySunny {
  margin-top: 25px;
}

.inputWhySunny-navbar {
  border-radius: 0 !important;
}

.seeMoreWhySunny {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 17px;
  /* font-family: "Open Sans", sans-serif; */
  line-height: 1.6;
  background: #fff;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 40px;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(137, 44, 11);
}

/* Mobile---------------------------------------------------------------------------------------- */
.WhySunnyWrapContentMb {
  margin: 0 30px 30px 30px;
  padding-top: 20px;
}

.sunny-titleMb {
  margin-top: 36px;
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  line-height: 20px;
  color: #000;
  text-align: center;
}

.WhySunnyTitleMb {
  /* font-family: Roboto, sans-serif; */
  font-size: 60px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: center;
}

.WhySunnyContenMb {
  text-align: justify;
  /* font-family: Roboto, sans-serif; */
  line-height: 1.6;
  color: rgb(0, 0, 0);
  font-size: 38px;
}

.sunny-thumMb .media {
  margin: 30px 0;
}

.sunny-thumMb .media .media-bodyMb {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 19px;
  padding-left: 20px;
  color: #343a40;
  padding-top: 20px;
}

.sheet-box-whySunnyMb .title-customMb {
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 27px;
  color: #c75a16;
}

.sheet-box-whySunnyMb {
  margin: 24px 0;
  background: #f8e6bc;
  padding: 62px 70px;
}

.inputWhySunny-navbarMb {
  padding: 23px 32px !important;
  font-size: 35px !important;
}

.whySunny-errorDiv{
  margin-top: 10px;
}

.btnWhySunnyHome{
  margin-bottom: 50px;
}

.text-danger-Mb {
  margin-top:22px;
  font-size: 33px;
  color: red;
}

.btn-WhySunny-Mb {
  margin-top: 25px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 35px !important;
  line-height: 20px !important;
  background: #892c0b !important;
  width: 100%;
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.seeMoreWhySunny-mb {
  margin-left: 30px;
  font-size: 38px;
  /* font-family: "Open Sans", sans-serif; */
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 20px;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(137, 44, 11);
}