.title-dichvu {
  font-size: 35px;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: center;
  margin-bottom: 40px;
}

.section-head {
  margin-bottom: 50px;
}

.site-filters {
  margin-bottom: 30px;
}

.image-tab {
  margin: 0 auto;
  text-align: center;
}

/* slide */

.slick-prev::before,
.slick-next::before {
  color: #000;
}

.service-img {
  border-radius: 5px;
  width: 427px;
  height: 240px;
}

/*L-desktop*/
@media only screen and (min-device-width: 1224px) and (max-device-width: 1400px) {
  .service-slide-tab {
    height: 710px !important;
  }
}

.service-slide-tab {
  width: 99% !important;
  background: #892c0b;
  border-radius: 5px;
  height: 670px;
  position: relative;
}

.service-titleTab {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.service-contentTab {
  font-size: 16px;
  line-height: 1.6;
  color: rgb(253, 253, 253);
  margin: 0 8px 10px 8px;
}

.service-contentTab-1 {
  min-height: 150px;
  padding: 0 10px;
  text-align: justify;
}

.service-contentTab ul {
  margin-left: 0px;
  list-style-type: disc;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8;
  color: rgb(255, 255, 255);
  height: 140px;
  margin-bottom: 0px;
}

.service-btnTab {
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
}

.service-taovandon {
  margin-top: 45px;
}

.service-taovandon button {
  background-color: #892c0b;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 10px 25px;
  border-radius: 5px;
  border: solid 2px #dc6115;
}

.service-wrapBtnTab {
  position: absolute;
  bottom: 20px;
}

.service-wrapBtnTab-mb {
  position: absolute;
  bottom: 40px;
}

/* ===================================mobile============================= */
.title-dichvu-mb {
  font-size: 60px;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: center;
  margin-bottom: 40px;
}

.service-wrapIcon {
  width: 20%;
}

.image-tab-mb {
  margin: 0 auto;
  text-align: center;
}

.Service-icon-imgMb {
  width: 120px;
}

.service-icon {
  width: 80px;
}

/* --------------------------------------- */

.Service-wrap-slide-mb {
  margin: 0 30px;
  font-size: 38px;
}

/* .service-img-mb {
  border-radius: 25px 25px 0 0 !important;
} */
.service-wrapTab-mb {
  background-color: #892c0b;
  color: #fff
}

.service-titleTab-mb {
  text-align: center;
  font-weight: bold;
  padding-top: 40px;
}

.service-contentTab-mb {
  text-indent: 10px;
  padding: 0 30px;
  text-align: justify;
  height: 1018px;
}

.service-contentTab-mb ul {
  margin-left: 0px;
  list-style-type: disc;
  font-weight: bold;
  line-height: 1.8;
  color: rgb(255, 255, 255);
  height: 340px;
}

.service-contentTab-1-mb {
  height: 350px;
}

.service-btnTab-mb {
  border: none;
  border-radius: 8px;
  padding: 10px 35px;
}

.service-taovandon-mb {
  margin-top: 45px;
}

.service-taovandon-mb button {
  background-color: #892c0b;
  font-size: 38px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 15px 30px;
  border-radius: 8px;
  border: solid 2px #dc6115;
}


.service-contentTab>div>h1:first-child {
  display: none;
}

.service-contentTab>div>ul {
  position: absolute;
  bottom: 60px;
}

.service-contentTab>div>p {
  margin-bottom: 10px;
}