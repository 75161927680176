.ContactWrap {
    padding-top: 30px;
    background: #f4f7f8;
    padding-bottom: 30px;
}

.ContactTitle {
    /* font-family: "Open Sans", sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    text-align: center;
}

.ContactWrapTab {
    margin-top: 50px !important;
}

.ContactWrapContent {
    font-size: 20px;
    /* font-family: Roboto, sans-serif; */
    line-height: 1.6;
    color: rgb(0, 0, 0);
}

.ContactIconImg {
    margin-right: 50px;
}

/* ==================mobile=========================== */
.ContactTitleMb {
    /* font-family: "Open Sans", sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    text-align: center;
    font-size: 45px;
}
.ContactWrapContentMb{
    margin: 0 30px;
    font-size: 36px;
    /* font-family: Roboto, sans-serif; */
    line-height: 1.6;
    color: rgb(0, 0, 0);
}
.ContactTextMb{
    text-align: center;
    margin-bottom: 50px;
}
.ContactWrapTabMb{
    margin-bottom: 50px;
}
.ContactDKBG{
    font-size: 40px !important;
}
.ContactInput{
    padding: 10px 30px !important;
    font-size: 35px !important;
}
.ContactBtn{
    height: 70px !important;
    font-size: 36px !important;
}