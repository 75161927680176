.shipment-block {
  background: #fff;
  margin-top: 50px;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.05);
}

.TrackingShipmet-title-page {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.6;
  padding: 10px;
  color: #fff;
  background: #c15b37;
  text-align: center;
}

.tcd {
  margin-right: 20px;
  margin-bottom: 7px
}

.shipment-body {
  padding: 35px 50px;
}

.shipment-body input {
  height: 45px;
  padding: 5px 24px;
  width: 89%;
}

.shipment-body button {
  width: 261.6px;
  height: 45px;
  background: #dc6115;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 25px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
}

.wrapper-shipment {
  background: #fff;
  width: "100%";
}

.error-search {
  margin-top: 15px;
  color: red;
}

/* ======================mobile============================= */
.TrackingShipmet-title-page-mb {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 1.6;
  padding: 10px;
  color: #fff;
  background: #c15b37;
  text-align: center;
}

.shipment-body-mb {
  padding: 35px 50px;
  font-size: 36px;
}

.shipment-body-mb input {
  padding: 20px 24px;
  width: 60%;
}

.shipment-body-mb button {
  background: #dc6115;
  box-sizing: border-box;
  border-radius: 3px;
  margin-left: 25px;
  border: none;
  color: #fff;
  line-height: 1.6;
  padding: 20px 24px;
}