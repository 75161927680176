.SlideIntro-slide {
  height: 700px;
  width: 100%;
}

.slider-container {
  position: relative;
}

.slide-title {
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: black;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.slick-prev::before,
.slick-next::before {
  content: "";
}