.wrap-navbar-top {
  padding: 6px 0;
  background-color: #4c1f0c;
}

.mg-icon-navbar {
  margin-left: 8px
}

.font-icon {
  font-size: 20px;
}

.search-language {
  margin-left: auto;
}

/* ---------- search -----------*/
.search-container {
  position: relative;
  float: left;
}

.inputSearch {
  padding: 3px 10px;
  font-size: 17px;
  width: 250px;
  border-radius: 20px;
}

.inputSearch:focus {
  outline: none;
}

.btnSearch {
  background: none;
  position: absolute;
  top: 0;
  right: 6px;
  padding: 3px 3px 2px 2px;
  border: none;
  cursor: pointer;
}

.languageFlags {
  margin-left: 20px;
}

.fixFlag-nav {
  margin: 0 5px;
}

.flag-nav-icon {
  width: 45px;
  cursor: pointer;
}

.logoSunny {
  width: 200px;
}

/* ------------------------------------------------------------------------------------------------------------------ */
.navbar-bottom-w {
  align-items: center;
  padding: 0px;
  z-index: 999;
  transition: all 0.2s ease-in-out;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 0px;
}

.list-menu-nav {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.li-nav-pc {
  margin-left: 32px;
  /* font-family: Roboto, sans-serif; */
  font-size: 18px;
  font-weight: 500;
}

.li-nav-pc-active {
  margin-left: 32px;
  /* font-family: Roboto, sans-serif; */
  font-size: 18px;
  font-weight: 600;
  color: #cf1d1d;
}


/* mobile--------------------------------------------------------- */
.navBar-wrapMb {
  margin: 0 40px;
}

.logoSunnyMb {
  width: 250px;
}

/* toggle sidebar =============*/
.wrap-toggle-navbar {
  margin-left: auto;
}

.icon-toggle-navbar {
  font-size: 63px;
}

.navbar-menuMobile{
  font-size: 38px !important;
}

.navbar-dropmenu-item {
  font-size: 25px;
  border-bottom: dashed 2px #000000 !important;
  padding: 0.5rem 1rem !important;
}

.navbar-dropmenu-itemm {
  font-size: 25px;
  padding: 0.5rem 1rem 0 1rem !important;
}

.navbar-dropmenu-itemL {
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}

/* end */

.NavBar-toggleItem {
  display: none;
}