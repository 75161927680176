.mb-80 {
    margin-bottom: 80px;
}

.mb-100{
    margin-bottom: 100px;
}

.bottom-10{
    bottom: 10%;
}

.bottom-5{
    bottom: 5%;
}

.color-primary{
    color: rgb(137, 44, 11);
}