.flex-div {
  display: flex;
}

.text-center{
  text-align: center;
}

.center-lmh {
  margin: auto;
}

.center-lmh1 {
  margin: 0 auto;
}

.center-lmh2 {
  margin: auto 0;
}

.clw {
  color: #fff;
}

.clb {
  color: #000;
}
