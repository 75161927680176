.AdsMyServiceTitle {
  /* font-family: Roboto, sans-serif; */
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(137, 44, 11);
  text-align: center;
  padding-right: 35px;
}

.AdsMyServiceList {
  margin-top: 10px;
  border-left: 5px solid #892c0b;
  color: #892c0b ;
  /* font-family: Roboto, sans-serif; */
  font-size: 17px;
  line-height: 1.6;
}
.AdsMyServiceList ul {
  list-style-type: disc;
}
.AdsMyServiceList ul a{
  text-decoration: none;
  color: #892c0b ;
}
.AdsMyServiceList ul li {
  padding-right: 35px;
}
.dashedStyle{
    line-height: 0.3;
}
 .AdsMyServiceListLi {
    padding-top: 6px;
    padding-right: 30px;
}

.AdsMyService-item1{
  margin-top: 10px;
}
.AdsMyService-item2{
  display: none;
}