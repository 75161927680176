.AirTransportTitle {
    margin-top: 50px;
    text-align: center;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    font-size: 30px;
}

.AirTransportWrapContent {
    margin-bottom: 30px;
    color: #000;
}

.AirTransportPosts-1 {
    text-indent: 20px;
    font-size: 18px;
    text-align: justify;
}

.AirTransportPosts {
    font-size: 18px;
}

.AirTransportVideo {
    margin-top: 20px;
}

.AirTransportImg {
    margin-top: 30px;
}

.AirTransportImgNote {
    font-size: 16px;
    font-style: italic;
}

.AirTransportListTitle {
    margin-top: 30px;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    font-size: 20px;
}

.AirTransportListWrap ul {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.AirTransportListWrap ul li::before {
    content: "► ";
}

.AirTransportContact {
    margin-top: 45px;
    font-style: italic;
}

.AirTransportBtn {
    margin-top: 20px;
    border: 2px solid #000;
    background: #fff;
    padding: 2px 20px;
    border-radius: 5px;
    font-weight: 500;
}

/* ====================mobile================= */
.AirTransportWrapContentMb {
    color: #000;
}

.AirTransportPosts-1Mb {
    font-size: 36px;
    padding: 0 40px;
    text-align: justify;
}

.AirTransportPosts-2Mb {
    padding: 0 30px;
    text-align: justify;
    margin-top: 20px;
}

.AirTransportPostsMb {
    font-size: 36px;
}

.AirTransportTitleMb {
    margin-top: 30px;
    text-align: center;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    font-size: 45px;
}

.AirTransportVideoMb {
    margin-top: 20px;
    padding: 0 30px;
}

.AirTransportImg img {
    padding: 0 30px;
}

.AirTransportListWrapMb {
    padding: 0 30px;
}

.AirTransportListTitleMb {
    margin-top: 20px;
    /* font-family: Roboto, sans-serif; */
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    font-size: 40px;
}

.AirTransportListWrapMb ul {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.AirTransportListWrapMb ul li::before {
    content: "► ";
}

.AirTransportContactMb {
    margin-top: 15px;
    font-style: italic;
    text-indent: 20px;
    font-size: 38px;
}

.AirTransportBtnMb {
    margin-top: 20px;
    border: 2px solid #000;
    background: #fff;
    padding: 2px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    font-weight: bold;
}

.AirTransportPosts>div>ul {
    list-style: unset;
}


.AirTransportPosts>div>.media {
    justify-content: center;
}

figure.image {
    margin: 0 auto;
}

.AirTransportPosts>div>h1:first-child {
    display: none;
}

.AirTransportPosts-1Mb>div>h1:first-child {
    display: none;
}

.AirTransportPosts-1Mb>div>figure {
    justify-content: center;
}