.p-t70 {
  padding-top: 70px;
}

.section-head {
  margin-bottom: 50px;
}

.abc {
  font-style: italic;
  font-weight: 400;
  font-size: 26px;
  line-height: 20px;
  text-align: center;
  color: #940604;
}

.title-news {
  font-size: 35px;
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: Arial, Helvetica, sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: #892c0b;
  text-align: center;
}

.news-wrapper-title {
  align-items: center;
  margin: 0 auto;
  width: 15%;
}

.dez-media img,
.dez-post-media img {
  width: 100%;
  height: auto;
}

.blog-post {
  position: relative;
  margin-bottom: 30px;
}

.dez-media,
.dez-media,
.dez-post-media {
  position: relative;
}

.dez-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
}

.date-style-3 .dez-post-info {
  position: relative;
}

.p-t20 {
  padding-top: 20px;
}

#tintuc .post-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #343a40;
  margin-bottom: 30px;
}

.title {
  color: #2c4a6b;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.more-link {
  background-color: #f5f5f5;
  cursor: pointer;
  /* border-radius: 9px; */
  border: none;
  padding: 1px 0px;
  color: #26aa5d;
}

.more-link:hover {
  color: #015214;
}

.more-text {
  display: none;
}

.title:hover {
  color: #26aa5d;
}

.link {
  text-decoration: none;
  color: #26aa5d;
}

.link:hover {
  color: #015214;
}

.left-line,
.right-line {
  flex-grow: 1;
  height: 2px;
  margin: 0 10px;
  background-color: #940604;
}

.news-wrapContent {
  margin: 40px;

}

.news-contentTab {
  padding: 0 10px;
  height: 260px;
  border: none;
}

.news-img {
  width: 100%;
  /* height: 202px; */
}

.news-content-text {
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(86, 29, 9);
  text-align: justify;
  cursor: pointer;
  text-decoration: none;
}

.news-content-text:focus {
  border: unset;
}

.news-btnThem {
  font-weight: bold;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  font-size: 20px !important;
  border-radius: 5px;
  margin: 0 20px 30px 20px;
  padding: 5px 30px;
}

/* =====================mobile========================================== */
.news-content-text-mb {
  margin-top: 10px;
  /* font-family: Roboto, sans-serif; */
  font-size: 38px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(86, 29, 9);
}

.title-news-mb {
  font-size: 60px;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: bold;
  line-height: 1.6;
  color: #892c0b;
  text-align: center;
}

.news-wrapper-title-mb {
  align-items: center;
  margin: 0 auto;
  width: 46%;
}

.left-line-mb,
.right-line-mb {
  flex-grow: 1;
  height: 4px;
  margin: 0 10px;
  background-color: #940604;
}

.news-btnThem-mb {
  font-weight: 700;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  font-size: 38px;
  border-radius: 8px;
  margin: 0 20px 30px 20px;
  padding: 10px 60px;
  border: 2px solid #000;
}

.news-wrapContent-mb {
  margin: 20px;
}

.news-wrapBtnAdd {
  margin-top: 60px;
}

.slick-track>.slick-slide>div>.news-contentTab>a{
  text-decoration: unset;
}
.DetailNewsContent>div>figure{
  justify-content: center;
}
