.IntroductionListTitle {
    /* font-family: Roboto, sans-serif; */
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    padding-right: 35px;
}

.IntroductionListContent{
    margin-top: 10px;
    border-left: 5px solid #892c0b;
    color: #892c0b;
    /* font-family: Roboto, sans-serif; */
    font-size: 17px;
    line-height: 1.6;
}
.IntroductionListContent ul {
    list-style-type: disc;
}