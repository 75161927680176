.HotNewsTitle {
    /* font-family: Roboto, sans-serif; */
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    color: rgb(137, 44, 11);
    padding-right: 35px;
}

.HotNewsListContent {
    padding-right: 35px;
}

.HotNewsWrapCt {
    margin-top: 25px;
}

.HotNewsImg {}

.HotNewsContent {
    font-style: italic;
    margin-top: 10px;
    font-weight: 700;
}