.ListService-WrapTab {
  padding: 0 5px 0 10px;
  color: #fff;
}

.ListService-img {
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.ListServiceTitle {
  font-size: 18px;
  line-height: 1.6;
  font-weight: bold;
  text-align: center;
}

.ListServiceWrapContent {
  height: 400px;
  background: #892c0b;
  text-align: justify;
  padding: 15px 15px;
  position: relative;
}

.ListServiceWrapContent button {
  position: absolute;
  bottom: 15px;
  left: 50%;
  border-radius: 5px;
  padding: 8px 30px;
  transform: translateX(-50%);
}

/* ===================Mobile======================== */
.ListService-WrapTabMb {
  padding: 0 30px 0 30px;
  color: #fff;
  margin-bottom: 30px;
}

.ListServiceTitleMb {
  padding-top: 10px;
  font-size: 35px;
  line-height: 1.6;
  font-weight: bold;
  text-align: center;
}

.ListServiceWrapContentMb {
  height: 680px;
  background: #892c0b;
  text-align: justify;
  padding: 0 15px;
  position: relative;
}

.ListServiceWrapContentMb button {
  position: absolute;
  font-size: 30px;
  bottom: 20px;
  left: 15px;
  border-radius: 5px;
}

.ListServiceContentMb {
  font-size: 30px;
}

.ListService-WrapTabFix {
  padding: 0 5px 0 10px;
  color: #fff;
  margin-bottom: 25px;
}

.ListServiceContent>div>h1:first-child {
  display: none;
}

.ListServiceContent>div>ul {
  list-style: unset;
}

/* css phan trang */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.pagination a:hover {
  background-color: #ebebeb;
}

.pagination .active a {
  background-color: #007bff;
  color: #fff;
}

.row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.col {
  flex-basis: calc(33.33% - 10px);
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.disabled {
  display: none;
}

.previous.disabled,
.next.disabled {
  display: none;
}


/* ======================================================= */
.ListServiceContentMb>div>h1:first-child {
  display: none;
}

.ListServiceContentMb>div>ul {
  list-style: unset;
}

.ListServiceTitleMb .ListServiceTitle {
  font-size: 40px !important;
  margin: 10px 0 20px 0;
}

